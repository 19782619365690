@import url(https://fonts.googleapis.com/css?family=Lato);

.business-card-model{
    min-height: 100vh;
    width: 100%;
    background-color: black;
}




/* Variables */

/* CENTERING */


/* Mixin */

/* /end */





/* THE FRONT */
.front {
  
   
    background-position: center;
    
    background-size: cover;

}


.back img ,
.front img{
    
  width: 100%;
  height: 100%;
  object-fit: contain;
  

}


/* /end */

/* THE BACK */
.back {
    
    background-position: center;
    
    background-size: cover;
}


/* /end */

/* THE FLIP EFFECT */

/* entire container, keeps perspective */

.flip-container:hover .flipper {
  transform: rotateY(180deg);
  cursor: pointer;
}
.flip-container,
.front,
.back {
  height: 40vh;
  width: 40vw;
}
.flipper {
  transition: 0.5s;
  transform-style: preserve-3d;
 
  position: relative;
}
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  
}
.front {
  z-index: 2;
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

@media (max-width: 600px) {
    .flip-container,
    .front,
    .back {
      height: 250px;
      width: 100%;
    }
}