.video-container{
    max-height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
    gap: 2rem;
    width: 100%;
    padding: 0rem 0rem;
    background-color: rgba(0, 0, 0, 0.959);
}


.video-page{
    width: 100%;
    display: flex;
    
}

.display-video {
    width: 100%;
    height: 80vh;
    position: relative;
    overflow: hidden;
}

.display-video video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This makes the video cover the available space without distorting its aspect ratio */
  
}

.individual-videos {
    padding: 0 4vw;
    margin-top: 5%;
    height: 100%;
   
}

.video-title-and-arrow{
    width: 100%;
    padding: 1rem 0rem 3rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.video-cat-model-title{
  
    
    color: white;
    font-size: 6vh;
    letter-spacing: 3px;
    font-family: var(--font-monExtra);
    text-transform: capitalize;
    text-align: center;
}

.video-part-display{
    display: grid;
    gap: 5px !important;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-auto-rows: minmax(auto , auto) !important;
}


.video-div{
    
    display: flex;
    position: relative;
    flex-direction: column !important;
}

.video-play {
    position: absolute;
    font-size: 4rem;
    color: rgba(192, 11, 11, 0.808);
    display: none; /* Initially hide the play button */
}

.each-video-title {
    position: absolute;
    bottom: 0%; /* Adjust the position as needed */
    left: 0%;
    width: 100%;
    font-size: 4vh;
    text-align: center;
    color: #fff;
    margin: 0;
    font-weight: 200;
    padding: 10px;
    z-index: 100;
    background: rgba(0, 0, 0, 0.596);
    opacity: 1; /* Initially hide the title */
    transition: opacity 0.3s ease;
}

.video-part-model{
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.video-div:hover .play-overlay {
    opacity: 1; /* Make the overlay visible on hover */
}

.video-div:hover .video-play {
    display: block; /* Show the play button on hover */
}

.video-div:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the desired darkness */
    opacity: 0;
    transition: opacity 0.3s ease; /* Add a transition effect to the overlay opacity */
  }
  
  .video-div:hover:before {
    opacity: 1; /* Make the overlay visible on hover */
  }

  

  @media (max-width: 600px) {
    .video-container {
        padding: 0rem 0rem;
    }

    
    .display-video {
        height: 40vh;
    }

    .video-part-display {
        
        grid-template-columns: repeat(1, 1fr) !important;
        
    }

    .each-video-title {
        font-size: 2rem;
    }

    .video-play {
        font-size: 2rem;
    }

    .video-part-model {
        height: 35vh;
    }

    .video-title-and-arrow{
        width: 100%;
        padding: 3rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    
    .video-cat-model-title{
      
        
        color: white;
        font-size: 2rem;
        letter-spacing: 3px;
        font-family: var(--font-monExtra);
        text-transform: capitalize;
        text-align: center;
    }
    
}