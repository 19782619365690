.booklist-model{
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
}

.products {
    display: flex;
    width: 99%;
    min-height: 100vh;
    background-color: rgb(247, 247, 247);
    flex-direction: column;
    align-items: center;
    padding-bottom: 4vh;
    margin-bottom: 5vh;
  }
  
  .products .info {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .products .info button {
    padding: 5px;
    cursor: pointer;
  }

  .booking-div{
    display: flex;
    flex-direction: column;
  }
  
  @media (max-width: 600px) {

    .booklist-model{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .products {
        width: 100%; /* Adjust width for smaller screens */
    }

    .products .info {
        text-align: center; /* Center text for smaller screens */
    }

    .products .info button {
        margin-top: 10px; /* Add some space between the button and other elements for smaller screens */
    }
}