/* Add CSS styles for the image slider here */

.sliderBody {
    font-family: 'Roboto', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    /* background-position: center center;
    background-size: cover; */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.sliderContainer {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    height: 100vh;
    width: 50vw;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
}

.Slide {
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: auto;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0; /* Adjust this value as needed */
    left: 0; /* Adjust this value as needed */
    transition: opacity 0.4s ease;
  }

.Slide.Active {
    opacity: 1;
}

.Arrow {
    position: absolute;
    background-color: transparent;
    color: #fff;
    padding: 20px;
    font-size: 30px;
    top: 50%;
    cursor: pointer;
    z-index: 1000;
}

.Arrow:focus {
    outline: 0;
}

.leftArrow {
    left: 2%;
}

.rightArrow {
    right: 2%;
}


/* Mobile view adjustments */

@media (max-width: 767px) {
    .sliderContainer {
        height: 50vh;
        width: 90vw;
        max-height: 50vh;
        background-size: contain;
    }
    .leftArrow {
        left: 5vw;
    }
    .rightArrow {
        right: 5vw;
    }
}