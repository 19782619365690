/******** footer ********/
.footer {
    width: 100%;
    padding: 18px 10px;
    background-color: #141414f8; /* Updated background color for a modern look */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #ffffff; /* Updated text color for better contrast */
  }
  
  .footer-content {
    max-width: 600px;
  }
  
  .footer span {
    font-size: 20px;
    letter-spacing: 1px;
    color: #ffffff; /* Adjusted text color for a softer look */

  }

  .design-develop{
    font-family: 'Island Moments';
    font-size: 1.3rem !important;
  }
  
  @media (max-width: 600px) {
    .design-develop{
      
      font-size: .8rem !important;
    }
  }