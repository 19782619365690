.photo-update{
    
    padding: 0rem 1rem;
}

.ph-update-category{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}

.ph-update-title {
    margin-left: 1%;
}

.ph-update-title h1{
    color: white;
    font-weight: 100;
}

.ph-photos{
    width: 100%;
    height: 100%;
   
    padding: .5rem 2rem;
    display: flex;
    gap: .5rem;

}

.ph-single-photo{
    width: 24%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

}

.ph-single-photo img{
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.ph-single-title{
    position: absolute;
    opacity: 0;
}

.ph-single-title {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease; /* Add a transition effect to the title opacity */
  }
  
  .ph-single-photo:hover .ph-single-title {
    opacity: 1; /* Make the title visible on hover */
  }
  
  /* Add a black background overlay on hover */
  .ph-single-photo:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the desired darkness */
    opacity: 0;
    transition: opacity 0.3s ease; /* Add a transition effect to the overlay opacity */
  }
  
  .ph-single-photo:hover:before {
    opacity: 1; /* Make the overlay visible on hover */
  }

