.video-category{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dashboard-row{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1rem;
}

.dashboard-cat-heading{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.uploading-video{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.upload-video{
    position: relative;
    display: flex;
}

.video-remove{
    position: absolute;
    color: red;
    top: 2%;
    right: 1%;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

@media (max-width: 768px) {
    .dashboard-items .video-category {
        padding: 0.5rem; /* Adjust padding for smaller screens */
    }

    .dashboard-cat-box {
        width: 100%; /* Make each category box take the full width on smaller screens */
        margin-bottom: 1rem; /* Add some spacing between category boxes on smaller screens */
    }

    .uploading-video {
        padding: 0.5rem; /* Adjust padding for smaller screens */
    }

    .upload-video {
        max-width: 100%; /* Ensure the video container does not exceed the screen width */
    }

    .video-remove {
        top: 1%;
        right: 1%;
    }
 

}