.project-display-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* align-items: center; */
    color: white;
    height: 100%;
    width: 100%;
    padding-bottom: 2%;
    background-color: rgba(0, 0, 0, 0.959);
    overflow-x: hidden;
    /* Set your desired background color */
}

.gallery-photo{
  
}

.project-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    height: 100vh;
    /* Set your desired width for the slider container */
    /* max-width: 1200px; */
    /* Set the maximum width if needed */
}

.swiper-slide {
    width: 100%;
    height: 70vh;
    /* Set your desired height for the slides */
    object-fit: cover;
}

.mySwiper2 {
    width: 100%;
}

.swiper-bottom {
    margin-top: 5px;
    width: 100%;
    gap: 5px !important;
    display: flex;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.6)
    );
}

.swiper-bottom-part img {
    width: 100%;
    height: 100%;
  
}

.work-title-model{
    font-family: var(--font-monExtra);
}

.swiper-button-next,
.swiper-button-prev {
    color: red;
}

.swiper-main-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.swiper-bottom .swiper-slide {
    width: 80px;
    /* Width of the slide */
    height: 115px;
    /* Height of the slide */
    object-fit: cover;
    /* Maintain aspect ratio; cover the area completely */
    margin-right: 5px;
    /* Space between slides */
}

.swiper-bottom img {
    width: 100%;
    /* Ensure the image fills the slide container */
    height: 100%;
    /* Ensure the image fills the slide container */
    object-fit: cover;
    /* Maintain aspect ratio; cover the area completely */
    object-position: center;
    /* Center the image within the slide */
}

.project-display{
        padding: .5rem;
        margin-top: -5%;
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(350px, auto);
        grid-auto-flow:  dense;
}

.project-display div {
    background-size: cover;
    background-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    object-position: center;
}


.cat-links {
    position: relative;
}

/* .cat-links h1 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-transform: uppercase;
    text-decoration: underline;
    opacity: 0;
  
    transition: opacity 0.3s ease;
}

.cat-links:hover h1 {
    opacity: 0.9;
    
} */



.project-display-wedding{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}



.cat-2 {}

.cat-3 {}

.project-photos {
    width: 100%;
    /* padding: 0 3rem; */
    text-align: center;
    display: flex;
    padding: 0 4.1vw;
    flex-direction: column;
    gap: 4rem;
  }

  .project-photos h1{
    color: white !important;
  }

  .project-photos img{
    width: 32%;
    max-height: 400px;
    object-fit: cover;
  }
 



@media (max-width: 600px) {
    .project-slider {
        height: 80vh; /* Adjusted height for screens with a maximum width of 768px */
        
        margin-bottom: 6%;
    }

    .swiper-bottom {
        flex-wrap: nowrap; /* Prevent wrapping on screens with a maximum width of 768px */
    }

    .swiper-bottom .swiper-slide {
        flex: 0 0 auto; /* Ensure fixed size for screens with a maximum width of 768px */
    }

    .project-display {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjusted for screens with a maximum width of 768px */
        grid-auto-rows: minmax(100px, auto); /* Adjusted for screens with a maximum width of 768px */
    }
}
