.team-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100vw;
    padding: 5rem 2rem;
   
    background-color: #1C1C1C;
}

.team-members{
    height: 100%;
    width: 100%;
    
    gap: 2rem;
    padding: 1rem 5rem;
    display: flex;
    flex-wrap: wrap;

}

.single-member{
    width: 31%;
    height: 100%;
    
    padding: 0rem 3rem;
}

.photo-part{
    width: 96%;
    height: 350px;
    border: 1px solid white;
}

.photo-part img{
    width: 100%;
    height: 100%;
    transform: translate(-20px, -25px);
}

.member-desc h1{
    color: rgba(192, 13, 13, 0.788);
    font-weight: 100;
    text-transform: uppercase;
    font-size: large;
}

.member-desc{
    
    display: flex;
    
    flex-direction: column;
    gap: .5rem;
}

.member-role{
    color: white;
    margin-top: -2%;
}

.member-desc p{
    font-size: small;
    color: rgba(255, 255, 255, 0.747);
    
}

@media (max-width: 600px) {
    .team-members {
        padding: 1rem 2rem; /* Adjust padding for smaller screens */
        display: flex;
        flex-direction: column;
        gap: 5rem;
        
    }

    .photo-part img{
        width: 140%;
        height: 100%;
        transform: translate(-50px, -20px);
    }

    .single-member {
        width: 100%; /* Full width for smaller screens */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .photo-part{
        border: none;
        
    }

    .member-desc{
        margin-top: 5%;
        width: 150%;
        justify-content: center;
        align-items: center;
        
        
    }
}
