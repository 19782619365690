@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.demo-model{

}


  
  .wave {
 
  
    animation: wave  ease-in-out 1.5s infinite alternate;
    transform-origin: center -36px;
  }
  
  .wave:hover {
    animation-play-state: paused;
    cursor: pointer;
  }
  
  .wave img {
    border: 5px solid #f8f8f8;
    display: block;
    width: 15vw;
    height: 40vh;
    object-fit: cover;
  }
  

  
  .wave:after{
    content: '';
    position: absolute;
    width: 20px; 
    height: 20px;
    border: 1.5px solid #ffffff;
    top: -10px; 
    left: 50%;
    border-bottom: none;
    border-right: none;
    transform: rotate(35deg);
  }
         
  .wave:before{
    content: '';
    position: absolute;
    top: -23px;
    left: 50%;
    display: block;
    height: 44px;
    width: 47px;
    background-image: url(https://cdn.hashnode.com/res/hashnode/image/upload/v1602040503586/FW6g79WBY.png);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    z-index: 16;
  }
          
  @keyframes wave {
    0% { transform: rotate(2deg); }
    100% { transform: rotate(-2deg); }
  }
  

/* -----------------------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------------------------*/


figure.snip1113 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;


  width: 100%;
  background: hsl(0, 0%, 100%);

  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

figure.snip1113 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

figure.snip1113 img {
  max-width: 65%;
  margin: 6vh auto;
  display: block;
  position: relative;
  border: .7vh solid #666666;
  padding: 15px 15px 85px 15px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

figure.snip1113 figcaption {
  position: absolute;
  height: 20vh;
  bottom: 0px;
  left: 0;
  right: 0;
  display: block;
}

figure.snip1113 h3 {
  background-color: #ffffff;
  color: #000000;
  font-size: 3.5vh;
  width: 100%;
  padding: 5px 12px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}

figure.snip1113 h3 span {
  font-weight: 800;
}

figure.snip1113 h4 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 1;
  background-color: #3a3939;
  font-size: 2.5vh;
}

figure.snip1113.blue img {
  border-color: #2472a4;
}

figure.snip1113.blue h3,
figure.snip1113.blue h4 {
  color: #2472a4;
}

figure.snip1113.red img {
  border-color: #ab3326;
}

figure.snip1113.red h3 {
  color: #ab3326;
}


figure.snip1113.red h4 {
    color: white;
  }
figure.snip1113.yellow img {
  border-color: #e08e0b;
}

figure.snip1113.yellow h3,
figure.snip1113.yellow h4 {
  color: #e08e0b;
}

figure.snip1113.green img {
  border-color: #229955;
}

figure.snip1113.green h3,
figure.snip1113.green h4 {
  color: #229955;
}

figure.snip1113.orange img {
  border-color: #d67118;
}

figure.snip1113.orange h3,
figure.snip1113.orange h4 {
  color: #d67118;
}

figure.snip1113.navy img {
  border-color: #2b3c4e;
}

figure.snip1113.navy h3,
figure.snip1113.navy h4 {
  color: #2b3c4e;
}


/* Demo purposes only */


/* ----------------------ACCORDION----------------------------------------------ACCORDION---------------------------------------- */

/*Section used for centering process*/


.containerrrr{
  width: 100%;
  /* max-width: 80rem; */
  display: flex;
  flex-direction: column;
  gap: 3vh;
  padding: 0 1.5rem;
  letter-spacing: 2px;
}

.dedicated-title{
  letter-spacing: 3px;
  font-weight: 100;
  font-size: 4vh;
}

.accordion-item{
  background-color: transparent;
  border-radius: .4rem;
}

.accordion-item hr{
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.accordion-link{
  font-size: 2.5vh;
  letter-spacing: 2px;
  color: rgba(255,255,255,.8);
  text-decoration: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.accordion-link .flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-link h3{
  font-weight:500;
  font-size: 3vh;
  letter-spacing: 2px;
}

.accordion-link i{
  color: #e7d5ff;
  padding: .5rem;
  
}

.accordion-link ul{
  display:flex;
  align-items: flex-end;
  list-style-type: none;
  margin-left: 25px;
}

.accordion-link li{
  font-size: 1.7vh;
  letter-spacing: .5px;
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0 1px 5px;
}

.accordion-link div{
  display: flex;
}

.accordion-link .ion-md-arrow-down{
  display: none;
}

.answer{
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  transition: max-height 650ms;
}



.answer p{
  color: #fff;
  font-size: 2vh;
  text-align: center;
  letter-spacing: 2px;
  padding: 2rem;
  
}

.accordion-item:target .answer{
  max-height: 20rem;
}

.accordion-item:target .accordion-link .ion-md-arrow-forward{
  display: none
}

.accordion-item:target .accordion-link .ion-md-arrow-down{
  display: block;
}





/* ----------------------ACCORDION----------------------------------------------ACCORDION---------------------------------------- */

  
  @media (max-width: 600px) {
    .wave img {
        border: 5px solid #f8f8f8;
        display: block;
        width: 10rem;
        height: 13rem;
        object-fit: cover;
      }
      .containerrrr{
        width: 100%;
        /* max-width: 80rem; */
     
        padding: 0 0rem;
      }

      .dedicated-title{
        text-align: center;
        letter-spacing: 3px;
        font-weight: 100;
        font-size: .9rem;
      }

  
      .accordion-link .flex{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: normal;
        justify-content: normal;
      }

      
      .accordion-item hr{
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
      
      .accordion-link{
        font-size: 1rem;
        color: rgba(255,255,255,.8);
        text-decoration: none;
       
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
      }
      
      .accordion-link h3{
        font-weight:500;
        font-size: .8rem;
      }
      
      .accordion-link i{
        color: #e7d5ff;
        padding: .5rem;
        
      }
      
      .accordion-link ul{
        display:flex;

        align-items: flex-end;
        list-style-type: none;
        margin-left: 25px;
      }
      
      .accordion-link li{
        font-size: 10px;
        color: rgba(255, 255, 255, 0.4);
        padding: 0 0 1px 5px;
      }
      
      .accordion-link div{
        display: flex;
      }
      
      .accordion-link .ion-md-arrow-down{
        display: none;
      }
      
      .answer{
        max-height: 0;
        overflow: hidden;
        position: relative;
     
        transition: max-height 650ms;
      }
      
      
      
      .answer p{
        color: #fff;
        font-size: .8rem;

        padding: 2rem;
        
      }
      
      .accordion-item:target .answer{
        max-height: 20rem;
      }
      
    
      
      
  }


  