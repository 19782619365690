.booking-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    
    
    gap: 2rem;
    width: 100vw;
    padding: 5rem 2rem;
    background-color: #1C1C1C;
}

.booking-title{
    display: flex;
    gap: .5rem;
    align-items: center;
}

.booking-line{
    height: 1px;
    width: 100px;
    border: 1px solid rgba(255, 255, 255, 0.692);
}

.booking-title h1{
    font-size: x-large;
    color: rgba(255, 255, 255, 0.747);
    font-weight: 200;
    letter-spacing: 1px;
    
}

.pro-pack{
    font-size: 9px;
    color: white;
}

.booking-category{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 2rem 8rem;
    
}

.book-details{
    width: 32%;
    height: 400px;
    border: 1px solid rgb(192, 188, 188);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    transition: border 0.3s ease;
}

.book-details:hover{
    border: 2px solid white;
}

.single-booking-title{
    text-align: center;
    display: flex;
    margin-top: 8%;
    flex-direction: column;
    gap: .5rem;
    letter-spacing: 2px;
}

.single-booking-title h1{
    font-weight: 100;
    color: rgba(214, 12, 12, 0.815);
}

.single-booking-title span{
    font-size: small;
    color: rgba(231, 229, 229, 0.623);
}

.single-booking-details{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    letter-spacing: 1px;
    gap: .5rem;
}

.single-booking-details p{
    font-size: small;
    font-weight: 100;
}

.book-me-btn{
    width: 100%;
    height: 50%;
    border-top: 1px solid white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.book-me-btn:hover {
    background-color: rgb(15, 15, 15);
}

.book-me-btn h1{
    font-weight: 100;
    letter-spacing: 2px;
    font-size: large;
    color: rgba(202, 9, 9, 0.863);
}

.book-me-now{
    height: 100%;
    color: #1C1C1C;
    background-color: #1C1C1C;
    width: 100vw;
}

.book-me-now option{
    color: black;
}

.book-me-from{
    display: flex;
    
    flex-direction: column;
    gap: .5rem;
    
}

.book-me-from label{
    color: black;
}

.book-btn {
    border: 1px solid green;
    outline: none;
    margin-top: 2%;
}

.book-btn:hover {
    
    background-color: green;
}

.book-btn p{
    color: #1C1C1C;
}

.khalti-logo{
    height: 7rem;
    width: 9rem;
    cursor: pointer;
    transform: translateX(110%);
}

@media (max-width:600px) {
    .booking-container {
      padding: 4rem 1rem;
    }
  
    .booking-category {
      padding: 2rem;
    }
  
    .book-details {
      width: 100%;
      height: auto;
      padding-top: 4%;
      margin-bottom: 2rem;
    }

    .khalti-logo{
       
        transform: translateX(55%);
    }
  
    .single-booking-title {
      margin-top: 0;
    }

    .book-me-btn{
        padding: .7rem 0rem;
    }
  }