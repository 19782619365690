@import url('https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900');


.home-main-model{
    height: 100%;
    width: 100%;
   background-color: rgba(0, 0, 0, 0.959);
    overflow-x: none;
}



.home-model-forntpage{
    height: 100vh;
    width: 100%;
    display: flex;
}


/* ----------------------------Background------------------------------------------------------------------------------ */
.background-model{
    min-height: 100vh;
    width: 100%;
    background-color: rgb(0, 0, 0);
}

.video-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    pointer-events: none;
  }
  
  #video-bg {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    min-width: 100%;
    background-position: center center;
    background-size: cover;
    object-fit: cover;
    transform: rotate(180deg) ;
  }

  .content{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero-title {
    font-size: 8vw;
    line-height: 1em;
    font-weight: 900;
    font-family: 'Rubik', sans-serif;
  
  }

  /* .front-left-inner-div h1 {  
    text-transform: Uppercase;
    margin-bottom: .5em;
    font-family: 'Rubik', sans-serif;
    font-size: 9vh;
    color: #e6e4e4; } */

  .blend {
    mix-blend-mode: difference !important;
    color: #bbb9b9;
    position: relative;
    z-index: 2;
  }
  
/* ----------------------------Background------------------------------------------------------------------------------ */

.front-model-left {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    padding: 0rem 4.1vw;
}

.front-left-inner-div{
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 2vh;
}



    


.interior-text{
    margin-top: -2vh;
    font-family: 'Pirata One', cursive ;
    letter-spacing: 1.5px;
    font-size: 4.5vh;
    color: #831414d0;
    /* color: #F7971E; */
    text-align: center;
}

    
.interior-text span {
   
    font-family: 'Pirata One', cursive;
    letter-spacing: 1.5px;
  
}

.front-model-right {
    width: 40%;
    height: 100%;
    
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 2fr);
    /* grid-auto-rows: minmax(50px, 200px); */
     grid-auto-flow: dense;
}

.front-grid-div{
    
   
   
}

.front-grid-div.one{
    grid-column: span 1;
    grid-row: span 2;
    background-image: url("../../img/col-1_1.jpg");
    background-size: cover;
        background-position: center;
}


.front-grid-div.two{
    grid-column: span 1;
    grid-row: span 4;
    background-image: url("../../img/col-1_2.jpg");
    background-size: cover;
        background-position: center;
}

.front-grid-div.three{
    grid-column: span 1;
    grid-row: span 3;
    background-image: url("../../img/col-1_3.jpg");
    background-size: cover;
        background-position: center;
}

.front-grid-div.four{
    grid-column: span 1;
    grid-row: span 4;
    background-image: url("../../img/col-1_4.jpg");
    background-size: cover;
        background-position: center;
}

.front-grid-div.five{
    grid-column: span 1;
    grid-row: span 3;
    background-image: url("../../img/col-1_5.jpg");
    background-size: cover;
        background-position: center;
}

.service-model{
    width: 100%;
    height: 100%;
    margin-top: 5%;
    padding-bottom: 3vh;
}

.title-model{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 6vh 5vw;
}

.title-model h3{
    font-size: 2vh;
    letter-spacing: 1.3px;
    color: rgba(165, 27, 27, 0.856);
}


.service-div{
    padding: 0 5vw;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 150vh;
    gap: 5px;
}

.about-model-each-div h1{
    color: rgba(212, 3, 3, 0.623);
}

.service-each-div{
    position: relative;
    width: 49%;
    padding: 2.2vh 3vw;
    height: 32%;
   
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.service-each-div h3{
    font-size: 9vh;
    
    letter-spacing: 4px;
    font-family: var(--font-monSemi);
}


.service-desc{
    margin-top: 3%;
    width: 100%;
    height: 100%;
    
}

.service-each-div h1{
    font-size: 2.8vh;
    letter-spacing: 3px;
    color: rgba(165, 27, 27, 0.856);
}


.service-hexagon{
    position: absolute;
    /* height: 155px;
    width: 155px; */
    height: 25vh;
    width: 11vw;
    
    transform: rotate(90deg) scale(1.2);
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    overflow: hidden;
       right: 0;
}

.service-each-div.hexagon1 .service-hexagon{
    left: 0;
}

.service-hexagon.wedding1 img{
    position: absolute;
    object-position: top; /* Center the image inside the hexagon */
}

.service-hexagon.wedding2 img{
    position: absolute;
    object-position: bottom; /* Center the image inside the hexagon */
}



.service-hexagon.event3 img{
    position: absolute;
    object-position: bottom; /* Center the image inside the hexagon */
}

.service-hexagon.event1 img{
    position: absolute;
    object-position: bottom; /* Center the image inside the hexagon */
}

.service-hexagon.event2 img{
    position: absolute;
    object-position: left; /* Center the image inside the hexagon */
}


.service-hexagon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotate(-90deg);
}

.service-hexagon:nth-child(1){
    right: 14.3vw;
   
}

.service-each-div.hexagon1 .service-hexagon:nth-child(1){
    left: 14.4vw;
}


.service-hexagon:nth-child(2){
    right: 7.5vw;
    bottom: -2vh;
}

.service-each-div.hexagon1 .service-hexagon:nth-child(2){
    left: 7.5vw;
    bottom: -2vh;
}

.service-hexagon:nth-child(3){
    right: 21.6vw;
    bottom: -2vh;
}

.service-each-div.hexagon1 .service-hexagon:nth-child(3){
    left: 21.6vw;
    bottom: -2vh;
}

.about-model{
    margin-top: 7%;
    width: 100%;
    height: 100%;
   
}

.about-model-div{
    padding: 0 5vw;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 170vh;
    gap: 15px;
}

.about-model-each-div{
    position: relative;
    width: 49%;
    padding: 2.2vh 3vw;
    height: 49%;
    border-radius: .7rem;
    overflow: hidden; /* Ensure pseudo-element stays within the div */
    display: flex;
    flex-direction: column;
    gap: 5rem;
  
}


.about-model-each-div:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assests/newpic/camera1-removebg.png'); /* Update with the correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4; /* Adjust the opacity to make it look like a watermark */
    z-index: 0; /* Place the pseudo-element behind the content */
}

.about-model-each-div:nth-child(3)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assests/newpic/camera3-removebg-preview.png'); /* Update with the correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4; /* Adjust the opacity to make it look like a watermark */
    z-index: 0; /* Place the pseudo-element behind the content */
}
.about-model-each-div h3, .about-model-each-div h1, .about-icons {
    position: relative; /* Ensure text and icons are above the pseudo-element */
    z-index: 1;
}

.about-model-each-div p{
    position: relative; /* Ensure text and icons are above the pseudo-element */
    z-index: 1;
}
.about-camera-watermark{
    position: absolute;
    filter: blur(10%);
}

.about-model-each-div p{
    letter-spacing: .3vw;
    font-size: 2.5vh;
    color: white;
    font-weight: 900;
    line-height: 4vh;
}

.about-model-each-div h3{
    letter-spacing: .3vw;
    font-size: 2.5vh;
    font-weight: 900;
    line-height: 4vh;
}

.about-model-each-div h1{
    letter-spacing: .3vw;
    font-size: 5vh;
   
    text-align: center;
    font-weight: 900;
    
}


.about-model-each-div:first-child{
   
    justify-content: flex-end;

   
}

.about-pic-info{
    width: fit-content;
    letter-spacing: .15vw;
    padding: 1vh 1vw;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.548);
}

.about-model-each-div:nth-child(4){

    justify-content: flex-end;
   
}


.about-model-each-div span{
    color: rgba(165, 27, 27, 0.856);
}

.about-icons{
    display: flex;
    gap: 1.5vw;
}

.about-icon-model{
    color: rgb(207, 206, 206);
    font-size: 5vh;
}

.about-icons img{
    width: 2.5vw;
    height: 5vh;
}

.contact-bottom{
	padding: 0 6vw;
    padding-bottom: 7%;
    color: white;
	display: flex;
	gap: 3vw;
}

.contact-blocks{
	width: 25%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 3vh;
	padding-bottom: 7vh;
	letter-spacing: .2vw;
}

.contact-blocks h3{
	font-size: 2.4vh;
}

.contact-studio-model{
    margin-top: 7%;
}

.contact-blocks p{
	color: rgb(153, 153, 153);
	font-size: small;
	line-height: 17px;
}

.contact-connect-icons{
	display: flex;
	gap: 1rem;
}

.GIT-icons{
	width: 3vw !important;
	height: 6vh !important;
	
    transition: .3s all ease;
}

.GIT-icons:hover{
	
	filter: grayscale(0%);
}
/*---------------------Button------------------------------ Button */

.cta {
    border: none;
    background: none;
    display: flex;

    cursor: pointer;
    color: white;
  }

  .service-arrow-added{
    height: 4vh;
    width: 2vw;
  }
  
  .cta span {
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 3vh;
    color: white;
    padding-right: 15px;
    text-transform: capitalize;
  }
  

  

  
  .hover-underline-animation {
    position: relative;
    color: white;
  
    padding-bottom: 20px;
  }
  
  .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  rgba(253, 0, 0, 0.637);
 
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  

/*---------------------Button------------------------------ Button */




@media (max-width: 600px) {
    .front-model-left {
        width: 100%;
        height: 100%;
        padding: 0rem 4.1vw;
       
    }

  
.about-model-each-div:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assests/newpic/camera1-removebg.png'); /* Update with the correct path */
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    opacity: 0.4; /* Adjust the opacity to make it look like a watermark */
    z-index: 0; /* Place the pseudo-element behind the content */
}

.about-model-each-div:nth-child(3)::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assests/newpic/camera3-removebg-preview.png'); /* Update with the correct path */
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    opacity: 0.4; /* Adjust the opacity to make it look like a watermark */
    z-index: 0; /* Place the pseudo-element behind the content */
}


    .hero-title {
        font-size: 3rem;
        line-height: 1em;
        font-weight: 900;
        font-family: 'Rubik', sans-serif;
      
      }
    
      .title-model h3{
        font-size: .8rem;
        letter-spacing: 1.3px;
        color: rgba(165, 27, 27, 0.856);
    }
    
    
    .front-model-right {
        display: none;
    }
    
    .front-grid-div{
        
       
       
    }
    
    .front-model-left{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

        
.front-left-inner-div h1 {  
    text-transform: Uppercase;
    margin-bottom: .5em;
    font-family: 'Rubik', sans-serif;
    font-size: 2.5rem;
    color: #e6e4e4; }
    
    .front-left-inner-div h1 {
    position: relative;
    background: linear-gradient(to right, #8DA9C4, #DBD3D8, #EFF1F3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}
    

    
    .interior-text  {
    margin-top: -2vh;
    font-family: 'Pirata One', cursive;
    letter-spacing: 1.5px;
    font-size:1.5rem;
    color: #831414d0;
    /* color: #F7971E; */
    text-align: center;
}
    
 
    .wrapperr{
        font-size: 2rem !important;
    }

    .service-model{
        
      
        padding-bottom: 3vh;
    }


    .service-div{
        padding: 0 5vw;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100vh;
        /* gap: 5px; */
    }
    
    .service-each-div{
        position: relative;
        width: 49%;
        padding: 2.2vh 3vw;
        height: 33%;
       
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    

    .service-hexagon{
        position: absolute;
        /* height: 155px;
        width: 155px; */
        height: 5.4rem;
        width: 5.4rem;
        transform: rotate(90deg) scale(1);
     
    }

    .service-hexagon:nth-child(1){
        right: 5.5rem;
       
    }
    
    .service-each-div.hexagon1 .service-hexagon:nth-child(1){
        left: 5.5rem;
    }
    
    
    .service-hexagon:nth-child(2){
        right: 2.8rem;
        top: 5.3rem;
    }
    
    .service-each-div.hexagon1 .service-hexagon:nth-child(2){
        left: 3rem;
        top: 5.5rem;
    }
    
    .service-hexagon:nth-child(3){
        right: 19vw;
        bottom: 1vh;
        opacity: 0;
    }
    
    .service-each-div.hexagon1 .service-hexagon:nth-child(3){
        left: 19vw;
        bottom: 1vh;
    }
    
    .service-each-div h3{
        font-size: 3rem;
        letter-spacing: 4px;
        
    }
    
    
 
    
    .service-each-div h1{
        font-size: .7rem;
        line-height: 1rem;
        letter-spacing: 3px;
        color: rgba(165, 27, 27, 0.856);
    }

    .about-model{
        margin-top: 0%;
        width: 100%;
        height: 100%;
        padding-bottom: 10%;
       
    }


    .about-model-div{
        padding: 0 5vw;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        gap: 3rem;
    }
    
    .about-model-each-div{
        position: relative;
        width: 100%;
        padding: 2.2vh 3vw;
        height: 24%;
        border-radius: .7rem;
       
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }
    
    .about-model-each-div h3{
        letter-spacing: 3px;
        font-size: .8rem;
        font-weight: 900;
        line-height: 1.5rem;
    }

    .about-model-each-div p{
        letter-spacing: 3px;
        font-size: .8rem;
        font-weight: 900;
        line-height: 1.5rem;
    }
    
    
    .about-model-each-div h1{
        letter-spacing: 3px;
        font-size: 2rem;
  
        text-align: center;
        font-weight: 900;
        line-height: 1.5rem;
    }
    
    .about-icons{
      
        gap: 1rem;
    }
    
    .about-icons img{
        width: 2rem;
        height: 2rem;
    }

    .about-model-each-div:nth-child(3){
        
        order: 4; /* Swap the order */
    }
    
    .about-model-each-div:nth-child(4){
        order: 3; /* Swap the order */
    }

    .contact-bottom{
		padding: 0 6vw;
		
		display: flex;
		gap: 2rem;
	}


	.contact-blocks:first-child{
		display: none;
	}

	.contact-blocks:nth-child(3){
		display: none;
	}
	
	.contact-blocks{
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-bottom: 50px;
		letter-spacing: 1px;
	}
	
	.contact-blocks h3{
		font-size: .83rem;
	}
	
	.contact-blocks p{
		color: rgb(82, 82, 82);
		font-size: small;
		line-height: 17px;
		width: 100%;
		
	}
	
	.contact-connect-icons{
		
		display: flex;
		
		gap: .5rem;
	}

	.GIT-icons{
		width: 2.5rem !important;
		height: 2.5rem !important;
		
	}
    
    .cta span {
        padding-bottom: 7px;
        letter-spacing: 4px;
        font-size: 2vh;
        color: white;
        padding-right: 15px;
        text-transform: capitalize;
      }
    
}
