

.menu .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.menu .item .title {
    font-size: 12px;
    font-weight: 200;
    color: rgba(219, 213, 213, 0.849);
    text-transform: uppercase;
}

.menu .item .listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    text-decoration: none;
    border-radius: 5px;
}

.listItemTitle{
    color: white;
}

.menu .item .listItem:hover {
    background-color: #bebaba;
}

@media (max-width: 600px) {
    .menu .item {
        margin-bottom: 15px; /* Adjust margin for smaller screens */
    }

    .menu .item .title {
        font-size: 11px; /* Adjust font size for smaller screens */
    }

    .menu .item .listItem {
        padding: 8px; /* Adjust padding for smaller screens */
    }
}