.nav{
    position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 4.1vw;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2000;
	transition: .3s;
}


.nav.active {
	/* From https://css.glass */
    background-color: #00000071;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	box-shadow: 0 -7px 24px -15px #ffffff;
}

.header-logo{
    height:12vh;
    width: 13.5vw;
    z-index: 100 !important;
}

.header-logo1{
    height:2.5rem;
    width: 4rem;
    z-index: 100 !important;
}

.header-left{
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 4px;
    justify-content: center;
}







.header-left p{
    font-size: larger;
    
}   

.product {
    position: relative;
}

.dropdown-content {
    width: max-content;
    height: auto;
    padding: 2rem 0rem;
    background: rgba(117, 117, 117, 0.774);
    z-index: -1;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    position: absolute;
    top: 15px;
    opacity: 0;  /* Initial opacity set to 0 */
    transition: opacity 0.3s ease-in-out;  /* Add transition for smooth animation */
}

.product:hover .dropdown-content {
    opacity: 1;  /* Change opacity to 1 on hover */
}

.dropdown-content a {
    color: white !important;
    padding: 5px;
    transition: background-color 0.3s ease-in-out;  /* Add transition for smooth background color change */
}

.dropdown-content a:hover {
    background-color: rgba(194, 194, 194, 0.747);
}

.navLinks{
    color: rgb(160, 160, 160) !important;
    font-size: 2.3vh;
    letter-spacing: 2px;
    position: relative; /* Add this line */
}

.navLinks:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: .3vh;
    bottom: -15%;
    left: 0;
    background-color:  rgba(253, 0, 0, 0.637);
 
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .navLinks:hover:after { /* Changed selector */
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navLinks.active{
    color: rgb(255, 255, 255) !important;
}

.sub-menu.active{
    color: rgba(255, 255, 255, 0.918) !important;
}

.header-right{
    display: flex;
    gap: 4vw;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
}

.header-right a{
    color: #000;
}

a{
    text-decoration: none;
}

.sub-menu{
    display: none;
    font-size: 2rem;
    color: rgb(145, 141, 141);
}

.responsive-nav{
    position: absolute;
    top: -500%;
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    opacity: 0;
    z-index: 200;
    transition: all cubic-bezier(0.19, 1, 0.22, 1)1s;
    
}

.res-close{
    position: absolute;
    top: 2%;
    right: 2%;
    color: white;
    font-size: 3rem;
}





.res-dropdown-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem .5rem;
}



/* ... Your existing CSS ... */

@media (max-width: 600px) {
    .sub-menu {
        display: block;
        transform: translateX(15%);
    }

    .header-logo{
        height:5rem;
        width: 9rem;
      
    }

    .navLinks {
        display: none;
    }

    .header-down-arrow{
        font-weight: 100;
        transform: translate(15% , 10%);
        font-size: 1.5rem;
        animation: bounce 2s infinite;
    }

    .nav{
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 4.1vw;
        
        top: 0;
        right: 0;
        left: 0;
        z-index: 2;
        transition: .3s;
    }
    .header-added-logo img{
        width: 4rem;
        height: 2.5rem;
    }

    .ub-logo{
        transform: translateX(10%);
    }
    
    

    .nav.active {
        
    }

    .navLinks.active {
        color: rgba(0, 0, 0, 0.918) !important;
    }

    .header-right {
        flex-direction: column; /* Stack items vertically */
        align-items: flex-end; /* Align items to the right */
        gap: 1rem;
    }

    .header-right a {
        color: white; /* Adjust color for better visibility */
    }

    .sub-menu {
        display: block;
        font-size: 2rem;
    }

    .dropdown-content {
        top: 40px; /* Adjust top position to avoid overlapping */
    }

    .dropdown-content a {
        color: white !important;
    }

    .responsive-nav{
        
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
    }

    .responsive-nav.active {
        position: fixed;
        top: 0;
        opacity: 1;
      }
      

    .responsive-nav a{
        text-decoration: none;
    color: rgb(255, 255, 255);
    transition: color 0.3s ease;
    letter-spacing: 2px;
    cursor: pointer;
    font-size: 2rem;
    text-transform: uppercase;

    }
    .res-dropdown-content a{
        font-size: 1rem ;
        text-align: center;
    }

    .res-navLinks{
        font-size: 2rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
    }

    .responsive-nav a:hover {
        color: white;
    }

    .header-logo1-responsive{
        
            height:10rem;
            width: 10rem;
            z-index: 100;
      
    }

    
}

@keyframes bounce {
    0%, 100% {
      transform: translateY(10%);
    }
    50% {
      transform: translateY(-6px);
    }
  }