.crt-vid{
    display: flex;
    width: 100%;

}

.create-vid-category{
    display: flex;
    flex-direction: column;
}

.create-vid-title{
    width: 50%;
}

