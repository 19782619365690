.dashboard-container{
    width: 100%;
    min-height: 100vh;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    background-color: rgb(36, 34, 34);
}

.dashboard-bottom{
    width: 100%;
    height: 100%;
    gap: .5rem;
    display: flex;
}

.menu-container{
    width: 15vw;
    height: 100%;
    
    padding: 0 1rem;
    
}

.dashboard-items{
    width: 85vw;
    
    padding-left: .5rem;
    height: 100%;
    border-left: 1px solid  rgb(5, 129, 67);
}


@media (max-width: 768px) {
    .menu-container {
        width: 100%;
        max-width: none;
    }

    .dashboard-items {
        width: 100%;
        max-width: none;
        margin-top: 20px; /* Add some spacing between menu and dashboard items */
    }

    .dashboard-bottom {
        flex-direction: column;
        align-items: center; /* Center items on smaller screens */
    }

    .products .info {
        text-align: center; /* Center text for smaller screens */
        margin-bottom: 20px; /* Add some spacing at the bottom */
    }

    .products .info button {
        margin-top: 10px; /* Add some space between the button and other elements for smaller screens */
    }

    /* Adjust DataGrid styles for smaller screens */
    .MuiDataGrid-root {
        width: 100% !important;
    }

    .MuiDataGrid-toolbarContainer,
    .MuiDataGrid-footerContainer {
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }

    .MuiDataGrid-colCell,
    .MuiDataGrid-cell {
        padding: 8px;
    }
}
