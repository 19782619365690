.service-page-model{
    min-height: 100vh;
    max-height: 100%;
    overflow-x: none;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.959);
}

.service-model-top{
    height: 70vh;
    position: relative;
    width: 100%;
    padding: 1vh 4.1vw;
    
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    
    justify-content: center;
}

.service-model-top h3{
    margin-bottom: 2vh;
    letter-spacing: 2px;
    z-index: 100;
    font-size: 2.5vh;
    color: rgba(231, 4, 4, 0.781);
}

.service-model-top h1{
    font-family: var(--font-monExtra);
    font-size: 7vh;
    z-index: 100;
    width: 45%;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

}

.service-model-top h1 span{
    font-family: var(--font-monExtra);
    color: rgba(209, 6, 6, 0.781);
}

.service-model-shapes{
    
    
}

.each-service-shapes{
    position: absolute;
    
    border-radius: 10rem;
    border: 1px solid rgba(219, 4, 4, 0.842);
}

.wave.service{
    position: absolute;
    
}


.wave.service:first-child{
    left: 20.5vw;
    top: 10vh;
}



.wave.service:nth-child(2){
    right: 20.5vw;
    bottom: 2vh;
  
}

.wave.service:nth-child(3){
    left: 20.5vw;
    bottom: 2vh;
 
}

.wave.service:nth-child(4){
    right: 20.5vw;
    bottom: 2vh;
   
}

.each-service-shapes img{
    width: 100%;
    border-radius: 10rem;
    object-fit: cover;
    height: 100%;
    transform: translate(2% , -2%);
}

.service-model-bottom{
    padding: 4vh 4.1vw;
    display: flex;
    height: 100%;
    width: 100%;
    gap: 3vw;
}



.service-packages h2{
 
    font-size: 4vh;
    color: rgba(224, 9, 9, 0.747);
}

.service-packages h3{
    font-size: 2vh;
}

.service-packages h4{
   text-decoration: underline;

}

.service-dedicated-hours {
  padding: 2vh 4.1vw;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.service-dedicated-hours h2{
  color: white;
  font-family: var(--font-monExtra);
  letter-spacing: 3px;
  font-size: 7vh;
}

.service-dedicated-hours h2 span{
  font-family: var(--font-monExtra);
  color: rgba(209, 6, 6, 0.781);
}

.dedicated-hour-div{
  display: flex;
  margin-top: 3%;
  width: 100%;
}





	

/* -----------------------------------accordion gallery------------------------accordion gallery------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------------------------*/

        
        .containery {
            display: flex;
            justify-content: center;
            align-items: center;
        
            overflow: hidden;
        
        }
  
  .containery .cardy {
    flex: 1;
   
    transition: all 1s ease-in-out;
    height: 105vh;
    position: relative;
  }
  
  .containery .cardy .card__heady {
    color: black;
    background: rgba(3, 146, 3, 0.938);
    padding: 0.5em;
    transform: rotate(-90deg);
    transform-origin: 0% 0%;
    transition: all 0.5s ease-in-out;
    min-width: 100%;
    text-align: center;
    letter-spacing: .3vw;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.5em;
    white-space: nowrap;
  }
  
  .containery .cardy:hover {
    flex-grow: 10;
  }
  
  .containery .cardy:hover img {
    filter: grayscale(0);
  }
  
  .containery .cardy:hover .card__heady {
    text-align: center;
    top: calc(100% - 2em);
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 2em;
    transform: rotate(0deg) skew(-5deg);
  }
  
  .containery .cardy img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease-in-out;
    filter: grayscale(100%);
  }
  
  .containery .cardy:not(:nth-child(5)) {
    margin-right: 1em;
  }

  .containery .cardy .pricing-details {
    position: absolute;
    color: white;

    opacity: 0;
 
    z-index: 1; /* Ensure it's above the background */
    transition: opacity 0.5s ease; /* Add transition for smooth effect */
    display: flex;
  
    padding: 2vh 3vw;
    gap: 3vh;
    letter-spacing: 3px;
    flex-direction: column;

    background-color: hsla(0, 0%, 0%, 0.651);
  }
  .pricing-details::before,
.pricing-details::after {
  content: "";
  left: 10%;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.685);
  margin: 0px 0; /* Adjust the margin as needed */

}

.demo-video-section{
  position: absolute;
  display: flex;
  gap: 1rem;
  bottom: 13vh;
  padding: .3vh 3vw;
  background-color: hsla(0, 0%, 0%, 0.651);
  left: 10%;
  z-index: 100;
  opacity: 0;

}

.demo-video-section h2{
  font-size: 4vh;
  letter-spacing: 2px;
  font-weight: 100;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: white;
}

.demo-video-arrow{
  font-size: 1.5rem;
}

.containery .cardy:hover .demo-video-section {
  opacity: 1;
}

  .containery .cardy:hover .pricing-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    left: 10%;
    top: 5%;
    
    z-index: 1; /* Ensure it's above the background */
    opacity: 1; /* Hide the content on hover */
  }
  
  .containery .cardy .pricing-details h4,
  .containery .cardy .pricing-details h3 {
    transition: color 0.5s ease; /* Add transition for smooth effect */
  }

  .pricing-details ul{
    margin-left: 6%;
    line-height: 2.2rem;
    font-size: 1.1rem;
  }

  /*-------------------------------------------------------------------------------------------------------------------------------  */


  .glightbox_video{
    cursor: pointer;
  }

  .outer_circle {
    stroke-width: 2;
    stroke-dasharray: 410; 
    height: 20px ;
     stroke-dashoffset: 0;
    stroke-linecap: square;
    transition: all .4s ease-out;
  }
  
  .glightbox_video:hover .outer_circle {
  stroke-dashoffset:410;
    transition: stroke .7s .4s ease-out, stroke-dashoffset .4s ease-out
  }
  
  .glightbox_video:hover 
  .inner-circle {
    fill: #BF2428;
    transition:fill .4s .3s ease-out;
    
  }
  
  .glightbox_video:hover
  .play{
      fill: white;
    transition:fill .4s .3s ease-out;
  }
  





   /*-------------------------------------------------------------------------------------------------------------------------------  */
  



  /* -----------------------------------accordion gallery------------------------accordion gallery------------------------------------------------------------
  -------------------------------------------------------------------------------------------------------------------------------*/
  
.service-saying-div{
    position: relative;
    width: 100%;
    height: 50vh;
  
    padding: 0 4.1vw;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}

.service-saying-div p{
    font-size: 2.5vh;
    letter-spacing: 2px;
    line-height: 2rem;
}




.service-saying-div h3{
    position: absolute;
    font-size: 2.5vh;
    right: 5vw;
    bottom: 15vh;
    letter-spacing: 3px;

}




@media (max-width: 600px) {
    .service-model-top h3{
        margin-bottom: 2vh;
        letter-spacing: 2px;
        z-index: 100;
         background-color: rgba(0, 0, 0, 0.26);
        font-size: 1rem;
        color: rgba(231, 4, 4, 0.781);
    }


    .containery {
        flex-direction: column;
      }
    
      .containery .cardy {
        flex: none; /* Add margin between cards */
        margin: 1rem .5rem;
        height: 130vh;

      }

      .pricing-details ul{
        margin-left: 8%;
        line-height: 1.8rem;
        font-size: .9rem;
      }

      .dedicated-hour-div{
        display: flex;
        margin-top: 3rem;
        flex-direction: column;
        gap: 3rem;
        width: 100%;
      }
      
      .service-dedicated-hours h2{
        color: white;
        font-family: var(--font-monExtra);
        font-size: 2rem;
        letter-spacing: 2px;
      }
      
  
      

      
      .demo-video-section{
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        bottom: 1vh;
        padding: .3vh 3vw;
        justify-content: center;
        align-items: center;
        background-color: hsla(0, 0%, 0%, 0.651);
        left: 0%;
        z-index: 100;
        opacity: 1;

      }

      .demo-video-section h2{
        font-size: 1rem;
        letter-spacing: 2px;
        text-align: center;
        font-weight: 100;
        display: flex;
        gap: 1rem;
        align-items: center;
        color: white;
      }

      .demo-video-arrow{
        transform: rotate(90deg);
        font-size: 1.5rem;
      }

.containery .cardy:hover .demo-video-section {
  opacity: 1;
}
      
     
      .containery .cardy:hover {
        flex-grow: 0;
      }

      .containery .cardy .pricing-details {
    
        left: 0%;
        top: 0%;
        gap: 2rem;
        z-index: 1; /* Ensure it's above the background */
        opacity: 1; /* Hide the content on hover */
      }
    
      .containery .cardy:hover .pricing-details {
        display: 1; /* Hide pricing details on hover */
        left: 0%;
        gap: 2rem;
       
      }
      .pricing-details h2{
        font-size: 1.2rem;
      }

      .containery .cardy:hover {
        flex-grow: 0;
      }
      
      .containery .cardy:hover img {
        filter: grayscale(0);
      }
      
      .containery .cardy:hover .card__heady {
        text-align: center;
       
       
        color: black;
        background: rgba(255, 30, 173, 0.75);
        font-size: 2em;
        transform: rotate(-90deg);
      }
      
      .containery .cardy img {
    
        filter: grayscale(0%);
      }
      

    .service-model-top h1{
        font-family: var(--font-monExtra);
        font-size: 1.8rem;
        background-color: rgba(0, 0, 0, 0.26);
        width: 100%;
        z-index: 100;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
    
    }

    /* --------------------------------------------------------------------------------------------------------------------------------- */

    
    /* ---------------------------------------------------------------------------------------------------------------- */
    
    .service-model-top h1 span{
        font-family: var(--font-monExtra);
        color: rgba(209, 6, 6, 0.781);
    }
    
    .service-model-shapes{
        
        
    }

   
    
    .each-service-shapes{
        position: absolute;
        
        border-radius: 10rem;
        border: 1px solid rgba(219, 4, 4, 0.842);
    }
    
    .wave.service:first-child{
        left: 4.1vw;
        top: 10vh;
    
    }
    
    .wave.service:nth-child(2){
        right: 4.1vw;
        bottom: 8vh;
    }
    
    .wave.service:nth-child(3){
        left: 20.5vw;
        bottom: 8vh;
      
        opacity: 0;
    }
    
    .wave.service:nth-child(4){
        right: 4.1vw;
        bottom: 8vh;
       
    }
    

      

    .service-model-bottom{
        padding: 4vh 4.1vw;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 3rem;
    }
    
    .service-packages{
        width: 100%;
        color: white;
        height: 60vh;
        
        background-color: hsla(0, 0%, 25%, 0.363);
        display: flex;
        justify-content: center;
        align-items: center;
        
        letter-spacing: 3px;
        flex-direction: column;
        text-align: center;
    }

    .pricing-details::before,
.pricing-details::after {
  content: "";


  background-color: white;

  margin-left: 1%;
}

.service-saying-div h3{
    position: absolute;
    font-size: 2.5vh;
    right: 5vw;
    bottom: 10vh;
    letter-spacing: 3px;
}

.service-saying-div{
    margin-top: -13%;
 
}

.service-saying-div p{
    font-size: 1rem;
    letter-spacing: 2px;
    line-height: 1.2rem;
}


}