.update-video{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0rem 2rem;
    
}

.project-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 50%;
    
}

.project-categories a {
    white-space: nowrap;
    text-decoration: none;
    color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

.project-categories a.active {
    color: red;
}

.project-main {
    margin-top: 2%;
    width: 75vw;
    height: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(auto, auto);
    grid-auto-flow: dense;
    transition: all 0.3s ease;
}

.box {
    overflow: hidden;
    margin-top: 2%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
    height: 70%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* border: 1px solid wheat; */
    /* Add transition for smooth animation */
}

.individual-video-title{
    text-transform: capitalize;
    margin-top: 2%;
    font-weight: 100;
    font-size: 1.3rem;
    text-align: center;
    color: white;
}

 




.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.project-deletion{
    position: absolute;
    right: 1%;
    color: rgb(109, 11, 11);
    z-index: 100;
    font-size: 2rem;
}