.dashboard-photo-create{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.create-photo-dashboard{
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    width: 50%;
    padding: 0 .5rem;
    gap: 2rem;
    background-color: rgba(19, 19, 19, 0.418);
}

.create-photo-title h1{
    font-weight: 100;
    font-size: x-large;
    color: white;
}

.photos-title-input{
    width: 100%;
}

.photo-requirement{
    display: flex;
    gap: 2rem;
    padding: 0rem 1rem;
    flex-direction: column;
}

.requirement-tile{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: black;
}

.requirement-btn{
    width: 100%;
    color: black !important;
    outline: none;
    
}



.uploading-photo{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.model-title{
    font-size: 2.3rem;
    text-align: center;
    font-weight: 100;
    letter-spacing: 1px;
}

.uploaded-photo {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    position: relative;
    padding-bottom: 10px; /* Add some padding to the bottom to prevent the horizontal scrollbar from overlapping with the content */
}

.upload-button{
    display: flex;
    gap: .5rem;
}

.image-container {
    
    position: relative;
    border-radius: 5px;
    flex: 0 0 auto; /* Ensure each container takes its content width without shrinking or growing */
}

.image-container img {
    width: 100%; /* Make sure the image takes 100% of the container's width */
    height: 7rem; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the container while maintaining its aspect ratio */
}

.photo-close {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: red;
    cursor: pointer;
}

.photo-close:hover {
    color: darkred;
}

@media (max-width: 768px) {
    .create-photo-dashboard {
        width: 80%;
    }

    .upload-button {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
        align-items: center; /* Center buttons on smaller screens */
    }
}
